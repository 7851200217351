import React from 'react';
import './App.css';
import Logo from './ProjectNexus_LogoJust_Trans.svg'; // Update this path to your logo

function App() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="logo-container">
        <img src={Logo} alt="Logo" className="logo" />
      </div>
      <div className="text-container mt-8">
        <h1 className="text-lg font-bold text-gray-800 line-1">“Project NEXUS” is a group of Cloud / Data Management Experts. </h1>
        <p className="text-lg text-gray-600 mt-4 line-2">
        Like a guild of craftmanship, we are providing high-quality advisory 
        </p>
        <p className="text-lg text-gray-600 mt-4 line-3">
        & technical support for your data management strategies.
        </p>
      </div>
    </div>
  );
}

export default App;